import React from 'react';

import Section from '../../../molecules/Section/Section';
import { customInnerContentStyles } from '../styles/HeroNewStyles';
import { Content, Heading, Subhead } from '../styles/NewsroomHeroStyles';

const NewsroomHero = () => (
  <Section
    innerStyle={customInnerContentStyles}
    backgroundImageName="light-hex"
  >
    <Content>
      <Heading>Welcome to the Newsroom</Heading>
      <Subhead>
        Here you will find all of the major news about Shopmonkey.
      </Subhead>
    </Content>
  </Section>
);

export default NewsroomHero;
