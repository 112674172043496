import { graphql } from 'gatsby';
import React from 'react';
import GeneralHead from '../components/generalHead';
import NewsroomHero from '../components/Hero/variants/NewsroomHero';
import Layout from '../components/layout';
import NewsRoomList from '../components/NewsRoom/NewsRoomList';
import SEO from '../components/seo';
import { colors } from '../styles/atoms/colors';
import {
  NewsroomBody,
  NewsRoomBottomCTA,
  NewsRoomSection,
} from '../styles/Components/Newsroom/NewsroomStyles';
import Link from '../utils/Link';

const Newsroom = ({ data }) => (
  <Layout forceShowHeader forceShowFooter forceAnnouncement>
    <NewsroomHero />
    <NewsroomBody>
      <NewsRoomSection id="articles" bgColor={colors.base.white}>
        <h4>Browse the latest news</h4>
        <NewsRoomList news={data.pressReleases?.nodes} />
      </NewsRoomSection>
      <NewsRoomBottomCTA className="bg-white text-center d-none">
        <div className="container">
          <h2 className="heading-title">Logos, Photos, and More</h2>
          <p className="sub-head">
            Access our official monday.com press kit here including image
            assets, and logos.
          </p>
          <Link to="#!" className="link">
            Our Press Kit
          </Link>
        </div>
      </NewsRoomBottomCTA>
    </NewsroomBody>
  </Layout>
);

export const Head = ({
  data: {
    newsroom: { seo, headHtml, indexable, schemaMarkup },
  },
}) => (
  <GeneralHead>
    <SEO
      seo={seo}
      headHtml={headHtml}
      indexable={indexable}
      schemaMarkup={schemaMarkup}
    />
  </GeneralHead>
);

export const query = graphql`
  {
    newsroom: datoCmsNewsroom {
      indexable
      headHtml
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      schemaMarkup {
        id
        schema
      }
    }
    categories: allDatoCmsNewsroomCategory {
      nodes {
        name
      }
    }
    pressReleases: allDatoCmsPressRelease(
      sort: { fields: datePublished, order: DESC }
      filter: { meta: { status: { eq: "published" } } }
    ) {
      nodes {
        id
        title
        category: newsroomCategory {
          name
        }
        datePublished(formatString: "MMMM DD, YYYY")
        excerpt
        featuredImage {
          gatsbyImageData(
            imgixParams: {
              fm: "webp"
              auto: "compress"
              maxW: 1080
              fit: "clip"
              q: 35
            }
          )
          url
          fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
            ...GatsbyDatoCmsFluid
          }
        }
        brandLogo {
          basename
          url
          gatsbyImageData(
            imgixParams: {
              fm: "webp"
              auto: "compress"
              maxW: 1080
              fit: "clip"
              q: 35
            }
          )
          fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
            ...GatsbyDatoCmsFluid
          }
        }
        slug
      }
    }
  }
`;

export default Newsroom;
